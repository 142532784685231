<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <header class="cls">projects</header>
  </transition>
    

  <main class="justify-center flex absolute w-full items-center top-[320px] md:top-[280px] font-cabinet font-light text-[20px] md:text-[28px] text-[#bcb8b1] border-[#535353b1]">New projects coming soon.. &#129323;</main>
</template>

<script>
import {useAnimations} from '../composables/Anima.js'
 export default {
  setup() {
    const {beforeEnter, enter} = useAnimations()

    return {beforeEnter, enter}
  }
 }    
</script>

<style>

</style>