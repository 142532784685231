<template>
  <HeadButtons/>
  <Navbar/>
  <router-view></router-view>
</template>

<script>
import Navbar from './components/Navbar.vue'
import HeadButtons from './components/HeadButtons.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    HeadButtons
  },
}

</script>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f2f2f2;
}

* {
  scrollbar-color: #8e8e8e #fff;
  scrollbar-width: thin;
}



</style>
