import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProjectView from '../views/ProjectView.vue'
import FunView from '../views/FunView.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
    
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'Sabina Potesilova | About' },
    component: AboutView
  },
  {
    path: '/projects',
    name: 'Project',
    meta: { title: 'Sabina Potesilova | Projects' },
    component: ProjectView
  },
  {
    path: '/gallery',
    name: 'Fun',
    meta: { title: 'Sabina Potesilova | 3D Gallery' },
    component: FunView
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: 'Sabina Potesilova | Contact' },
    component: ContactView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Sabina Potesilova';
  next();
});

export default router
