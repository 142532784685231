<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <h1 class="flex absolute justify-center items-center w-full top-[89px] md:top-[45px] 3xl:top-[80px] text-[35px] md:text-[65px] 3xl:text-[100px] font-extrabold font-cabinet uppercase text-[#c2c0bf] p-[10px] ">let's get in touch</h1>
  </transition>
  <h2 class="absolute flex justify-center items-center w-full  md:mt-[160px] 3xl:mt-[270px] xs:max-sm:mt-[180px] mt-[200px] 3xl:text-[50px] md:text-[30px] text-[25px] text-[#bcb8b1] font-cabinet font-medium ">Don't be shy! Hit me up! &#128071;</h2>
  <main class="flex relative md:p-[36px] justify-center top-[260px] md:top-[195px] 3xl:top-[380px] xs:max-sm:top-[225px] min-[428px]:top-[280px] ">
    <div class="flex flex-col p-[25px] lg:p-[20px] md:w-[450px] 3xl:w-[680px] 3xl:p-[45px] md:border-[#444444b1] md:border-[1px] 3xl:border-[2px] rounded-[30px] 3xl:rounded-[45px]">
      <div class="grid gap-6 3xl:gap-[30px] lg:gap-[20px] place-items-center md:px-4 3xl:px-6">
        <button class="cntc text-[#8a817c] shadow-md" @click="copyToClipboard('potesilovasaba@gmail.com')">
          <span class="icon-[simple-icons--gmail]" style="width: 20px; height: 20px;  margin-right: 10px; vertical-align: -0.125em; color:#ff7f51;"></span>potesilovasaba@gmail.com
        </button>
        <button class="cntc text-[#8a817c] shadow-md" @click="copyToClipboard('sabiabbaa.3d')">
          <span class="icon-[simple-icons--instagram]" style="width: 20px; height: 20px; margin-right: 10px; vertical-align: -0.125em; color:#ff7f51;"></span>sabiabbaa.3d
        </button>
        <button class="cntc text-[#8a817c] shadow-md" @click="copyToClipboard('ssababba')">
          <span class="icon-[simple-icons--discord]" style="width: 20px; height: 20px; margin-right: 10px; vertical-align: -0.125em; color:#ff7f51;"></span>ssababba
        </button>
      </div>
    </div>
  </main>
  <footer class="bottom-[0px] flex absolute text-[10px] justify-center w-full font-cabinet font-light text-[#8a817c]" >
    © Sabina Potěšilová. Last updated February '24
  </footer>
</template>

<script>
import {useAnimations} from '../composables/Anima.js'

export default {
  setup() {
 
    const {beforeEnter, enter} = useAnimations()

    const copyToClipboard = (text) => {
      const button = event.target
      const originalContent = button.innerHTML
      button.innerHTML = 'Copied! &#10004;'

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
          .then(() => {
            console.log('Text copied to clipboard')
            setTimeout(() => {
              button.innerHTML = originalContent
            }, 2000)
          })
          .catch((error) => {
            console.error('Failed to copy text: ', error)
            button.innerHTML = originalContent
          });
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = text
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        button.innerHTML = ' &#9989; Copied!'
        setTimeout(() => {
          button.innerHTML = originalContent
        }, 2000)
      }
    }

    return {copyToClipboard, beforeEnter, enter}
  }
}
</script>

<style>

</style>