<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <header class="cls">about me</header>
  </transition>
  <main class="p-[40px] xs:max-sm:p-[25px] flex absolute justify-center items-center w-full top-[110px] 3xl:top-[200px] xs:max-sm:top-[118px] x:top-[140px] s:top-[125px]">
    <p class="text-center xs:max-sm:w-[750px] xs:max-sm:text-[15.5px] md:text-[16px] 3xl:text-[25px] text-[14px] w-[680px] 3xl:w-[830px] text-[#bcb8b1] font-cabinet">
      From a young age I was fascinated by the world of computers and software. Along the way I became interested in digital drawing and after some time I discovered the magic of 3D art. Then I found my passion for frontend development. I have acquired a diverse set of skills and I hone them every day.
    </p>
      <div class="flex absolute w-full justify-center items-center xs:max-sm:top-[225px] md:top-[160px] 3xl:top-[290px] top-[215px]">
        <div class="max-h-fit md:w-[550px] 3xl:w-[750px] s:w-[360px] w-[350px] flex absolute  justify-center py-[10px] px-[10px] rounded-[22px] border-y-[1px] border-[#444444b1] md:text-[15px] 3xl:text-[20px]  text-[12px] font-cabinet text-[#c2c0bf] font-light shadow-md">{{fact}}
          <span class="absolute flex md:left-[25px] left-[12px] 3xl:text-[20px] md:text-[15px] text-[12px] text-[#ff7f51] font-medium">{{ currentFact + 1 }}/{{ facts.length }}</span>
          <button @click="getNextFact" class="flex absolute md:right-[20px] right-[10px] md:px-[10px] mt-[-2px] md:mt-[1.5px] 3xl:mt-[3.5px] rounded-[5px] border-[1px] text-[#c2c0bf] hover:text-[#ff7f51] border-[#535353b1] shadow-sm"><span class="icon-[mynaui--shuffle-alt]" style="width: 20px; height: 20px; vertical-align: -0.125em;"></span></button>
        </div>
      </div>
      <div class=" flex absolute justify-center 3xl:top-[317px] md:top-[182px] top-[221px]  md:h-[150px] md:w-[150px] h-[140px] w-[140px] lg:h-[115px] lg:w-[115px] 3xl:h-[250px] 3xl:w-[250px] xs:max-sm:top-[241px] x:top-[251px] s:top-[241px]">
        <img src="../components/assets/images/Pcmemoju.webp"> 
      </div>
      <div class=" 3xl:w-[690px] lg:w-[530px] md:w-[560px] w-[320px] max-h-fit flex absolute  justify-center py-[10px] rounded-[22px] border-y-[1px] border-[#444444b1] border-opacity-10 mt-4 3xl:top-[550px] lg:top-[281px] md:top-[315px] x:top-[375px] s:top-[365px]  xs:max-sm:top-[365px] top-[345px] overflow-x-hidden shadow-md">
        <div class="relative flex overflow-x-hidden font-cabinet text-[#8a817c] font-medium text-[13px] md:text-[16px] lg:text-[14px] 3xl:text-[20px] ">
          <div class="animate-marquee whitespace-nowrap">
            <span class="mx-4"><span class="icon-[simple-icons--vuedotjs]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Vue.js</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--tailwindcss]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Tailwind Css</span>
            <span class="mx-4"><span class="icon-[simple-icons--javascript]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>JavaScript</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--html5]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>HTML</span>
            <span class="mx-4"><span class="icon-[simple-icons--css3]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>CSS</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--blender]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125;"></span>Blender</span>
            <span class="mx-4"><span class="icon-[simple-icons--adobephotoshop]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Photoshop</span>
          </div>
          <div class="relative flex top-0 animate-marquee whitespace-nowrap">
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--vuedotjs]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Vue.js</span>
            <span class="mx-4"><span class="icon-[simple-icons--tailwindcss]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Tailwind Css</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--javascript]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>JavaScript</span>
            <span class="mx-4"><span class="icon-[simple-icons--html5]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>HTML</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--css3]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>CSS</span>
            <span class="mx-4"><span class="icon-[simple-icons--blender]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125;"></span>Blender</span>
            <span class="mx-4 text-[#ff7f51]"><span class="icon-[simple-icons--adobephotoshop]" style="width: 16px; height: 16px; margin-right: 6px; vertical-align: -0.125em;"></span>Photoshop</span>
          </div>
        </div>
      </div>
  </main>

</template>

<script>
import {ref, computed} from 'vue'
import { useAnimations } from '../composables/Anima'


const facts = [
  "My favorite writer is J. R. R. Tolkien",
  "My notebooks are usually full of doodles",
  "My favorite movies are Lord of the Ring and Star Wars",
  "As a child, I was extremely fascinated by touch screens",
  "My favorite videogame is World of Warcraft",
  "I've never tried sushi",
  "I like nature and space documentaries",
  "I love mountains",
  "I'm afraid of clowns",
  "I hate avocado",
]

export default {
  setup() {
    const {beforeEnter, enter} = useAnimations()

    const currentFact = ref(0)

    const fact = computed(() => facts[currentFact.value])

    function getNextFact() {
      currentFact.value = (currentFact.value + 1) % facts.length
    }

    return {fact, currentFact, getNextFact, facts, beforeEnter, enter}
  
  }
}
</script>

<style>
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 3s ease;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 2s ease;
}
</style>