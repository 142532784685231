import gsap from 'gsap'

export function useAnimations() {
  const beforeEnter = (el) => {
    el.style.transform = 'translateY(-60px)'
    el.style.opacity = 0
  }

  const enter = (el) => {
    gsap.to(el, {
      duration: 1,
      y: 0,
      opacity: 1,
      ease: 'bounce.out'
    })
  }

  return {beforeEnter, enter}
}