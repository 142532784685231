<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <h1 class="cls">3d gallery</h1>
  </transition>
  <!--<h2 class="flex  absolute w-full justify-center items-center text-[20px] top-[165px] text-[#97908c] font-cabinet font-medium">Some of my creations in blender</h2>-->
    <div class=" md:columns-3 3xl:columns-3 pb-24 gap-6 justify-around px-4 md:px-40 3xl:px-[200px] xs:max-sm:mt-[160px] mt-[200px] md:mt-[190px] 3xl:mt-[280px]">
      <div class="images">
        <img src="../components/assets/images/sabina-backroomsfinalpng.jpg" style="border-radius: 15px;">
      </div>
      <div class="images">
        <img src="../components/assets/images/sabina-asset2.jpg" style="border-radius: 15px;">
      </div>
      <div class="images">
        <img src="../components/assets/images/sabina-asset.jpg" style="border-radius: 15px;">
      </div>
      <div class="images">
        <img src="../components/assets/images/tucnakfinaopl.png" style="border-radius: 15px;">
      </div>
      <div class="images">
        <img src="../components/assets/images/backroomsik6.png" style="border-radius: 15px;">
      </div>
      <div class="images">
        <img src="../components/assets/images/untitled2.png" style="border-radius: 15px;">
      </div>
  </div>
</template>

<script>
import {useAnimations} from '../composables/Anima.js'
 export default {
  setup() {
    const {beforeEnter, enter} = useAnimations()

    return {beforeEnter, enter}
  }
 }
</script>

<style>

</style>
