<template>
  <div class="home">
    <header class="flex absolute w-full items-center justify-center top-[30px] text-[#8a817c] font-cabinet font-medium text-[16px] "><span class="text-[16px] text-[#ff7f51] px-[5px]">&#10048;</span>AVAILABLE FOR WORK<span class="text-[16px] text-[#ff7f51] px-[5.5px]">&#10048;</span></header>
    <h1 class="font-cabinet uppercase flex absolute text-[100px] md:text-[200px] top-[210px] md:top-[220px] 3xl:top-[160px] 2xl:top-[140px] 2xl:text-[240px] lg:top-[80px] lg:text-[220px] xl:top-[80px] 3xl:text-[325px] font-black justify-center w-full text-[#c2c0bf]">Sabi<span class="stroke">n</span>a</h1>
    <p class="flex absolute w-full justify-center top-[380px] font-cabinet text-[20px] md:top-[560px] lg:top-[380px] xl:top-[380px] 2xl:top-[480px] 3xl:top-[580px] 3xl:text-[30px] p-[10px] text-[#bcb8b1]">Frontend developer & 3D artist based in Hustopece, Czech Republic. &#128205;</p>
  </div>
</template>

<script>

</script>
 
<style scoped>
.stroke {
  -webkit-text-stroke-width: 2px;
  -webkit-text-fill-color: transparent;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 2s ease
}
</style>