<template>
  <nav class="fixed flex justify-center items-end w-full h-16 bottom-5 3xl:bottom-7 z-20">
    <div class="mx-3 flex h-fit w-fit min-w-0 gap-[10px] shadow-md rounded-[20px] border-[0.075rem] border-[#646464b1] bg-[#0a0a0a] p-[10px] backdrop-filter backdrop-blur-sm bg-opacity-15">
      <router-link to="/" @click="handleClick" >
        <div class="flex gap-[10px]">
          <button class="btn">
          <span class="icon-[mynaui--home]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
        </button>
      </div>
      </router-link>
      <router-link to="/about" @click="handleClick">
    <div class="flex gap-[10px]">
      <button class="btn">
        <span class="icon-[mynaui--info-square]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
      </button>
    </div>
  </router-link>
      <router-link to="/projects" @click="handleClick">
        <div class="flex gap-[10px]">
          <button class="btn">
            <span class="icon-[mynaui--archive]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
          </button>
        </div>
      </router-link>
      <router-link to="/gallery" @click="handleClick">
        <div class="flex gap-[10px]">
          <button  class="btn">
            <span class="icon-[mynaui--image]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
          </button>
        </div>
      </router-link>
      <div class="h-11 w-1 shrink-0 border-x-[0.05rem] border-[#646464b1]"></div>
      <button @click="muteSound" class="h-11 w-11 shrink-0 items-center justify-center rounded-[10px] border-y-[1px] border-[#535353b1] bg-[#2e2e2e] hover:bg-[#222222] transition-colors duration-150 text-[#c2c0bf] hover:text-[#ff7f51]">
        <span :class="!audioMuted ? 'icon-[mynaui--volume-high]' :'icon-[mynaui--volume-slash]'" style="width: 35px; height: 35px; margin-top: 3px;"></span>
      </button>
    </div>
  </nav>

</template>

<script>
const bloop = require("./assets/sounds/bloop-1.mp3")
import {ref} from 'vue'

export default {
  setup() {
    const audio = new Audio(bloop)
    const audioMuted = ref(false)

    const handleClick = () => {
      audio.play()
    }
    const muteSound = () => {
      audio.muted = audioMuted.value = !audio.muted
    }

    return {audio, handleClick, muteSound, audioMuted}
  }
}
</script>

<style>

</style>
