<template>
  <router-link to="/contact">
  <div class="flex absolute justify-center right-[13px] top-[17px]">
    <div class="hdr shadow-md">
      <button class="btn">
        <span class="icon-[mynaui--chat-messages]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
      </button>
    </div>
  </div>
  </router-link>
  <div class="flex absolute justify-center left-[13px] top-[17px]">
    <div class="hdr shadow-md">
      <a href="https://github.com/saabiii" target="_blank">
      <button class="h-11 w-11 shrink-0 items-center justify-center rounded-[10px] border-y-[1px] border-[#535353b1] bg-[#2e2e2e] hover:bg-[#222222] transition-colors duration-150 text-[#c2c0bf] hover:text-[#ff7f51]">
        <span class="icon-[mynaui--brand-github]" style="width: 35px; height: 35px; margin-top: 3px;"></span>
      </button>
      </a>
    </div>
  </div>
</template>

<script>
</script>